import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import Grid from "@mui/material/Unstable_Grid2";
import { t } from "common/localization/translate";
import { TitlesCoupons } from "./components/titlesCoupons";
import { CouponsTable } from "../coupons/components/CouponsTable";
import {
  createScreenCouponsUsersStore,
  ScreenCouponsUsersDataProvider,
} from "./store";
import { CancelCouponPopUp } from "./components/CancelCouponPopUp";
import { CancelHiddenCouponPopUp } from "./components/CancelHiddenCouponPopUp";
import { SuccessCancelCouponPopUp } from "./components/popUps/SuccessCancelCouponPopUp";
import { ExposeAndCancelButtons } from "./components/exposeAndCancelButtons";
import { IdNumForm } from "./components/idNumForm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    },
  },
}));
export const Coupons = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenCouponsUsersStore(rootStore));
  const classes = useStyles();
  const isAllowedToHideCoupons = rootStore.userInfoStore.isAllowedToHideCoupons;
  return (
    <ScreenCouponsUsersDataProvider value={store}>
      <Page className={classes.root} title={t("screenCouponsUser.title")}>
        <Grid
          className={classes.container}
          container
          item
          lg={12}
          xs={12}
          spacing={2}
        >
          <TitlesCoupons />
          <IdNumForm />
        </Grid>
        <br />
        {isAllowedToHideCoupons ? <ExposeAndCancelButtons /> : null}
        <CouponsTable />
        <CancelCouponPopUp />
        <CancelHiddenCouponPopUp />
        <SuccessCancelCouponPopUp />
      </Page>
    </ScreenCouponsUsersDataProvider>
  );
});
