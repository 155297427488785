import { dashboardTypes } from "screens/loginV2/store/logic/dashboardTypes";
import { filterScreensBySegmentFeaturesAndPermissions } from "./filterScreensBySegmentFeaturesAndPermissions";
import { getPagesWithDashboardPath } from "./getPagesWithDashboardPath";
import { getSegmentPathById } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";

export function addSegmentRouteParam({
    dashboardType,
    navigationConfig,
    currentSegmentId,
    segmentFeatures,
    isAllowedHideCouponTypes,
    isAllowedToSendNotification,
    isAllowedToHideCoupons,
    isCanPurchaseCoupons,
    isCanAddSteps,
    isShowSwitchBusinessScreen,
    isProjectManager,
    isSupplier,
    isAllowedToCheckEntitlement,
    language,
}) {
    if (dashboardType !== dashboardTypes.ORGANIZATION && dashboardType !== dashboardTypes.SUPPORT && dashboardType !== dashboardTypes.SUPPLIERS && dashboardType !== dashboardTypes.BUSINESS) {
        return navigationConfig;
    }
    const filteredPages = filterScreensBySegmentFeaturesAndPermissions({
        navigationConfig,
        ...segmentFeatures,
        isAllowedHideCouponTypes,
        isAllowedToSendNotification,
        isAllowedToHideCoupons,
        isCanPurchaseCoupons,
        isCanAddSteps,
        isShowSwitchBusinessScreen,
        isProjectManager,
        isSupplier,
        isAllowedToCheckEntitlement,
        language,
    });

    const segmentPath = getSegmentPathById(currentSegmentId);
    const pagesWithDashboardPath = getPagesWithDashboardPath(filteredPages, segmentPath, dashboardType);
    const newNavigationConfig = { ...navigationConfig[0] };
    newNavigationConfig.pages = pagesWithDashboardPath;
    return [newNavigationConfig];
}
